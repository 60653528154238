
// @ts-ignore
import moment from 'moment-timezone';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import VBDropdownSearch from '@/components/custom-views/vb-dropdown-search/classes/vb-dropdown-search.vue';
import Datepicker from '@/components/form/DatePicker.vue';
import InputButton from '@/components/form/InputButton.vue';
import Triangle from '@/components/icons/Triangle.vue';

@Component({
  components: {
    SectionHeading,
    SectionH1,
    VBDropdownSearch,
    Datepicker,
    InputButton,
    Triangle,
  },
})
export default class SmartPlugLogView extends Vue {
  dateFilterMenuVisible: boolean = false;
  dateFilterString: string | null = null;
  dateRangeFilter: any = null;
  defaultDateFilter: any = {
    start: moment()
      .startOf('day')
      .add(-40, 'days')
      .toISOString(),
    end: moment()
      .endOf('day')
      .toISOString(),
  };

  selectedPlug: any = null;

  filteredPlug: any = null;
  filteredDate: any = null;

  @Watch('dateRangeFilter')
  onDateRangeFilterChanged() {
    this.dateFilterMenuVisible = false;

    if (this.dateRangeFilter) {
      this.dateFilterString = this.getDateFilterDisplayString(this.dateRangeFilter);
    }
  }

  get dateFilterParams() {
    if (this.dateRangeFilter) {
      return {
        start: this.dateRangeFilter.start.toISOString(),
        end: moment(this.dateRangeFilter.end)
          .endOf('day')
          .toISOString(),
      };
    }
    return this.defaultDateFilter;
  }

  get dateFilterStringParams() {
    return this.dateFilterString || this.defaultDateFilterString;
  }

  get defaultDateFilterString() {
    return this.getDateFilterDisplayString(this.defaultDateFilter);
  }

  get contentRightMargin() {
    return this.selectedPlug ? '340px' : '0px';
  }

  get plugList() {
    return [
      {
        id: 0,
        title: 'ปลั๊ก 0',
      },
      {
        id: 1,
        title: 'ปลั๊ก 1',
      },
      {
        id: 2,
        title: 'ปลั๊ก 2',
      },
    ];
  }

  onSelectFilterPlug(plug: any) {
    this.filteredPlug = plug;
  }

  getBuddhistShortYearString(year: string): string {
    let buddhistYear = (parseInt(year) + 543).toString();
    return buddhistYear.substr(buddhistYear.length - 2);
  }

  getDateFilterDisplayString(dateObject: any) {
    let start = moment(dateObject.start)
      .locale('th')
      .format('D MMM YYYY');
    let startYear = start.substr(start.length - 4);
    start = start.replace(startYear, this.getBuddhistShortYearString(startYear));

    let end = moment(dateObject.end)
      .locale('th')
      .format('D MMM YYYY');
    let endYear = end.substr(end.length - 4);
    end = end.replace(endYear, this.getBuddhistShortYearString(endYear));
    return `${start} - ${end}`;
  }
}
